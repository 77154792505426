import { Grid, Typography } from "@mui/material";

export const mensaje = (estado) => {
  switch (Number(estado)) {
    case 0:
      return (
        <Grid item>
          <Typography
            sx={{
              backgroundColor: "#ebd234",
              border: "1px, solid",
              borderRadius: "25px",
              padding: 1,
              paddingInline: 3,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Inicio
          </Typography>
        </Grid>
      );
    case 1:
      return (
        <Grid item>
          <Typography
            sx={{
              backgroundColor: "black",
              border: "1px, solid",
              borderRadius: "25px",
              padding: 1,
              paddingInline: 3,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Informativo
          </Typography>
        </Grid>
      );
    case 2:
      return (
        <Grid item margin={"auto"}>
          <Typography
            sx={{
              backgroundColor: "#0288d1",
              border: "1px, solid",
              borderRadius: "25px",
              padding: 1,
              paddingInline: 3,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Finalizado
          </Typography>
        </Grid>
      );
    case 3:
      return (
        <Grid item>
          <Typography
            sx={{
              backgroundColor: "#d32f2f",
              border: "1px, solid",
              borderRadius: "25px",
              padding: 1,
              paddingInline: 3,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Error
          </Typography>
        </Grid>
      );
    case 4:
      return (
        <Grid item>
          <Typography
            sx={{
              backgroundColor: "#40d102",
              border: "1px, solid",
              borderRadius: "25px",
              padding: 1,
              paddingInline: 3,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Exito
          </Typography>
        </Grid>
      );
    default:
      return "d";
  }
};
