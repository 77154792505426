import { UserPlus } from "react-feather";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GavelIcon from "@mui/icons-material/Gavel";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PageviewIcon from "@mui/icons-material/Pageview";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FlagIcon from "@mui/icons-material/Flag";
import SavingsIcon from "@mui/icons-material/Savings";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PaidIcon from "@mui/icons-material/Paid";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import PaymentsIcon from "@mui/icons-material/Payments";

// --------------------- Items de seccion de navegacion ---------------------

const empadronamiento = [
  //LOS PRIVILEGIOS SON DE 300 EN ADELANTE
  {
    title: "Beneficiarios",
    children: [
      {
        title: "ABM",
        href: "/dashboard/empadronamiento/beneficiarios/abm",
        icon: UserPlus,
        privil: "302",
        actions: [
          {
            title: "Alta de afiliados",
            description: "Dar de alta a los afiliados",
            keywords: ["alta", "nuevo", "afiliados"],
          },
          {
            title: "Baja de afiliados",
            description: "Dar de baja a los afiliados",
            keywords: ["baja", "nuevo", "afiliados"],
          },
          {
            title: "Modificacion de afiliados",
            description: "Modificar a los afiliados",
            keywords: ["modificacion", "nuevo", "afiliados"],
          },
        ],
      },

      {
        title: "Reportes",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf",
        icon: AssessmentOutlinedIcon,
        privil: "303",
        actions: [
          {
            title: "Seleccionar reportes",
            description: "Reportes de beneficiarios",
            keywords: ["seleccionar", "reportes"],
          },
        ],
      },
    ],
    privil: "303",
  },
  {
    title: "Empresas",
    children: [
      {
        title: "ABM",
        href: "/dashboard/empadronamiento/empresas/abm",
        icon: UserPlus,
        privil: "304",
        actions: [
          {
            title: "Alta de Empresas",
            description: "Dar de alta a las empresas nuevas",
            keywords: ["alta", "nueva"],
          },
          {
            title: "Baja de Empresas",
            description: "Dar de baja a las empresas nuevas",
            keywords: ["baja", "nueva"],
          },
          {
            title: "Modificacion de Empresas",
            description: "Modificar a las empresas nuevas",
            keywords: ["modificacion", "nueva"],
          },
        ],
      },
      {
        title: "Reportes",
        href: "/dashboard/empadronamiento/empresas/reportes",
        icon: AssessmentOutlinedIcon,
        privil: "305",
        actions: [
          {
            title: "Seleccionar reportes por zona",
            description: "Reportes de empresas",
            keywords: ["zona", "buscar", "seleccionar"],
          },
          {
            title: "Seleccionar reportes por total",
            description: "Reportes de empresas",
            keywords: ["zona", "total", "seleccionar"],
          },
        ],
      },
      {
        title: "Afiliaciones",
        href: "/dashboard/empadronamiento/empresas/afiliaciones",
        icon: AssignmentIcon,
        privil: "305",
        actions: [
          {
            title: "Seleccionar reportes por zona",
            description: "Reportes de empresas",
            keywords: ["provincia", "seleccionar", "empresas"],
          },
        ],
      },
    ],
    privil: "305",
  },
  {
    title: "Localidades",
    href: "/dashboard/empadronamiento/nacionalidadesYLocalidades",
    icon: FlagIcon,
    privil: "312",
    actions: [
      {
        title: "Localidades",
        description: "ABM Localidades",
        keywords: ["altas", "bajas", "modificaciones", "localidades"],
      },
    ],
  },
  {
    title: "Archivos AFIP",
    href: "/dashboard/empadronamiento/archivos-afip",
    icon: FileCopyOutlinedIcon,
    privil: "306",
    actions: [
      {
        title: "Ver archivos de AFIP",
        description: "Revisar archivos de AFIP",
        keywords: ["Subir", "archivos"],
      },
    ],
  },
  {
    title: "Consulta Beneficiarios",
    href: "/dashboard/empadronamiento/consulta",
    icon: PersonSearchIcon,
    privil: "301",
    actions: [
      {
        title: "Consulta de beneficiarios",
        description: "Permite buscar beneficiarios y sus parientes",
        keywords: ["consulta", "afiliados", "beneficiarios"],
      },
    ],
  },
  {
    title: "Numero Empresa",
    href: "/dashboard/empadronamiento/consultaNumeroZonaEmpresa",
    icon: ManageSearchIcon,
    privil: "307",
    actions: [
      {
        title: "Numero Empresa",
        description: "Permite buscar zona y numero de empresa con el cuit",
        keywords: ["consulta", "empresa", "zona"],
      },
    ],
  },
  {
    title: "Reducciones de beneficiarios",
    href: "/dashboard/empadronamiento/reducBenef",
    icon: AnalyticsIcon,
    privil: "308",
    actions: [
      {
        title: "Reducciones de beneficiarios",
        description:
          "Permite buscar reducciones de benefeciarios por cuil, documento o nombre",
        keywords: [
          "consulta",
          "beneficiario",
          "reduccion",
          "reducciones",
          "cuil",
          "documento",
          "nombre",
        ],
      },
    ],
  },
  {
    title: "Reducciones de empresas",
    href: "/dashboard/empadronamiento/reducEmp",
    icon: AnalyticsIcon,
    privil: "309",
    actions: [
      {
        title: "Reducciones de empresas",
        description: "Permite buscar reducciones de empresas por cuit",
        keywords: [
          "consulta",
          "empresa",
          "reduccion",
          "reducciones",
          "cuit",
          "periodo",
        ],
      },
    ],
  },
  {
    title: "Discapacidad",
    href: "/dashboard/empadronamiento/discapacidad",
    icon: PersonSearchIcon,
    privil: "311",
    actions: [
      {
        title: "Discapacidad",
        description: "Modulo de discapacidad",
        keywords: ["agregar", "cambiar", "discapacidad"],
      },
    ],
  },
  {
    title: "Bajas automaticas",
    href: "/dashboard/empadronamiento/cumpleanos",
    icon: CalendarMonthIcon,
    privil: "310",
    actions: [
      {
        title: "Bajas automaticas",
        description:
          "Realizar la baja automatica de quien no tiene declaraciones dentro de los ultimos 4 meses",
        keywords: ["bajas", "automaticas", "falta DDJJ", "DDJJ"],
      },
    ],
  },
  {
    title: "Presentar novedades",
    href: "/dashboard/empadronamiento/presentarNovedades",
    icon: InfoIcon,
    privil: "313",
    actions: [
      {
        title: "Presentar novedades",
        description: "Permite presentar novedades cargando el archivo",
        keywords: ["presentar", "novedades", "carga"],
      },
    ],
  },
];

const administracion = [
  {
    title: "Privilegios",
    href: "/dashboard/administracion/privilegios",
    icon: UserPlus,
    privil: "801",
    actions: [
      {
        title: "Cambio de privilegios",
        description: "Cambiar privilegios ",
        keywords: ["modificar", "cambiar", "privilegios"],
      },
    ],
  },
  {
    title: "Ver usuarios",
    href: "/dashboard/administracion/verUsuarios",
    icon: UserPlus,
    privil: "802",
    actions: [
      {
        title: "Ver usuarios",
        description:
          "Crear, blanquear claves, bloquear, desbloquear o eliminar usuarios",
        keywords: [
          "crear",
          "cambiar",
          "usuarios",
          "blanquear",
          "crear",
          "contarseña",
          "desbloquear",
        ],
      },
    ],
  },
];

const cobranza = [
  {
    title: "Gestion de Recupero",
    href: "/dashboard/cobranza/gestionRecupero",
    icon: GroupsOutlinedIcon,
    privil: "502",
    actions: [
      {
        title: "Actualizar datos",
        description: "Control de recupero",
        keywords: ["actualizar", "datos", "control", "recupero"],
      },
    ],
  },
  {
    title: "Carga de actas",
    href: "/dashboard/cobranza/cargaDeActas",
    icon: UploadFileIcon,
    privil: "504",
    actions: [
      {
        title: "Subida de actas",
        description: "Subida de actas",
        keywords: ["carga", "subida", "actas"],
      },
      {
        title: "Buscar empresa",
        description: "Buscar empresa",
        keywords: ["buscar", "empresa"],
      },
    ],
  },
  {
    title: "Cheque rechazado",
    href: "/dashboard/cobranza/chequeRechazado",
    icon: DoNotDisturbOnOutlinedIcon,
    privil: "506",
    actions: [
      {
        title: "Cheque rechazado",
        description: "Rechazar cheque",
        keywords: ["rechazar", "cheque"],
      },
    ],
  },
  {
    title: "Ver judiciales",
    href: "/dashboard/cobranza/verJudiciales",
    icon: GavelIcon,
    privil: "505",
    actions: [
      {
        title: "Ver judiciales",
        description: "ver empresas con judiciales",
        keywords: ["judiciales", "empresas"],
      },
    ],
  },
  {
    title: "Pagos a cuenta",
    href: "/dashboard/cobranza/pagosACuenta",
    icon: PaymentsIcon,
    privil: "513",
    actions: [
      {
        title: "Pagos a cuenta",
        description: "Carga de pagos a cuenta",
        keywords: ["carga", "empresas", "pagos", "recibo"],
      },
    ],
  },
  {
    title: "Carga de convenios",
    href: "/dashboard/cobranza/cargaDeConvenios",
    icon: HandshakeIcon,
    privil: "509",
    actions: [
      {
        title: "Carga de convenios",
        description: "realizar una carga de convenios a la empresa",
        keywords: ["carga", "empresas", "convenios"],
      },
    ],
  },
  {
    title: "Recibos",
    href: "/dashboard/cobranza/recibos",
    icon: ReceiptOutlinedIcon,
    privil: "507",
    actions: [
      {
        title: "Recibos",
        description: "Declarar recibos",
        keywords: ["declarar", "recibos"],
      },
    ],
  },
  {
    title: "Reportes",
    href: "/dashboard/cobranza/reportes/rendicion",
    children: [
      {
        title: "Rendicion de cuentas",
        href: "/dashboard/cobranza/reportes/rendicion",
        icon: AssessmentOutlinedIcon,
        privil: "508",
        actions: [
          {
            title: "Reportes",
            description: "Reporte de rendicion de cuentas",
            keywords: ["rendicion", "cuentas"],
          },
        ],
      },
      {
        title: "Deudas por zonas",
        href: "/dashboard/cobranza/reportes/deudas",
        icon: RequestQuoteOutlinedIcon,
        privil: "508",
        actions: [
          {
            title: "Reportes",
            description: "Reporte de rendicion de cuentas",
            keywords: ["reporte", "deuda", "zonas"],
          },
        ],
      },
    ],
    privil: "508",
  },
  {
    title: "Cuenta corriente de empresas",
    href: "/dashboard/cobranza/cuentaCorriente",
    icon: RequestQuoteOutlinedIcon,
    privil: "508",
    actions: [
      {
        title: "Cuenta corriente de empresas",
        description: "Cuenta corriente de empresas",
        keywords: ["cuenta", "empresas", "corriente"],
      },
    ],
  },
];

const prePrestacional = [
  {
    title: "Ingreso",
    privil: "401",
    icon: KeyboardTabIcon,
    href: "/dashboard/pre-prestacional/ingreso",
    actions: [
      {
        title: "Ingreso pre prestacional",
        description: "Ingreso pre prestacional",
        keywords: ["ingreso", "nuevo", "prestacion"],
      },
    ],
  },
  {
    title: "Auditoria de solicitudes",
    privil: "402", //
    icon: VerifiedUserOutlinedIcon,
    href: "/dashboard/pre-prestacional/auditoriaDeSolicitudes",
    actions: [
      {
        title: "Buscar auditoria de solicitudes",
        description: "Dar de alta a los afiliados nuevos",
        keywords: ["Buscar", "auditoria", "afiliados", "nuevos"],
      },
    ],
  },
];

const actaVirtual = [
  {
    title: "Ver detalles",
    href: "/dashboard/actaVirtual",
    icon: PageviewIcon,
    privil: "201",
    actions: [
      {
        title: "Ver acta virtual",
        description: "Muestra el detalle del acta virtual",
        keywords: ["acta", "virtual", "detalles"],
      },
    ],
  },
];

const gerencia = [
  {
    title: "Tablero de control",
    href: "/dashboard/gerencia/tableroControl",
    icon: SavingsIcon,
    privil: "901",
    actions: [
      {
        title: "Tablero de control",
        description: "Muestra un resumen de la obra social",
        keywords: ["tablero", "control"],
      },
    ],
  },
];

const contaduria = [
  {
    title: "Reportes",
    href: "/dashboard/contaduria/reportes",
    icon: QueryStatsRoundedIcon,
    privil: "601",
    actions: [
      {
        title: "Reportes",
        description: "Muestra los reportes de contaduria",
        keywords: [
          "reporte",
          "contaduria",
          "suma",
          "sumarte",
          "subsidios",
          "monotributo",
        ],
      },
    ],
  },
];

const practicasHospital = [
  {
    title: "ABM",
    href: "/dashboard/practicashospital/abm",
    icon: UserPlus,
    privil: "302",
    actions: [
      {
        title: "Alta",
        description: "Dar de alta",
        keywords: ["alta", "nuevo"],
      },
      {
        title: "Baja",
        description: "Dar de baja ",
        keywords: ["baja", "nuevo"],
      },
      // {
      //   title: "Modificacion",
      //   description: "Modificar",
      //   keywords: ["modificacion", "nuevo"],
      // },
    ],
  },
  {
    title: "Autorización",
    href: "/dashboard/practicashospital/autorizacion",
    description: "Practicas pendientes de pago",
    icon: UserPlus,
    privil: "302",
    keywords: ["practicas", "pendientes", "pago"],
  },
];

// ------------------------ Secciones de navegacion ------------------------

const navItems = [
  {
    title: "Gerencia",
    home: "/dashboard/gerencia",
    icon: LeaderboardIcon,
    privil: 9,
    pages: gerencia,
  },
  {
    title: "Empadronamiento",
    home: "/dashboard/empadronamiento/",
    icon: GroupIcon,
    privil: 3,
    pages: empadronamiento,
  },
  {
    title: "Pre prestacional",
    home: "/dashboard/pre-prestacional/",
    icon: VolunteerActivismIcon,
    privil: 4,
    pages: prePrestacional,
  },
  {
    title: "Cobranza",
    home: "/dashboard/cobranza/",
    icon: CurrencyExchangeIcon,
    privil: 5,
    pages: cobranza,
  },
  {
    title: "Contaduria",
    home: "/dashboard/contaduria/",
    icon: CurrencyExchangeIcon,
    privil: 5,
    pages: contaduria,
  },
  {
    title: "Administracion",
    home: "/dashboard/administracion",
    icon: SupervisedUserCircleIcon,
    privil: 8,
    pages: administracion,
  },
  {
    title: "Acta Virtual",
    home: "/dashboard/actaVirtual",
    icon: PaidIcon,
    privil: 2,
    pages: actaVirtual,
  },
  //
  {
    title: "Practicas hospital",
    home: "/dashboard/practicashospital",
    icon: PaidIcon,
    privil: 2,
    pages: practicasHospital,
  },
];

export default navItems;
